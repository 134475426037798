import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import API from "./api";
import AppContext from "./context/app-context";
import "./scss/style.scss";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const TheLayout = React.lazy(() => import("./containers/TheLayout"));

// Pages
const Login = React.lazy(() => import("./views/pages/login/Login"));
const Page404 = React.lazy(() => import("./views/pages/page404/Page404"));
const Page500 = React.lazy(() => import("./views/pages/page500/Page500"));

class App extends Component {
  setUser = (user) => {
    this.setState({ ...this.state, user });
  };

  setLoading = (loading) => {
    this.setState({ ...this.state, loading });
  };

  state = {
    user: null,
    setUser: this.setUser,
    loading: false,
    setLoading: this.setLoading,
    loaded: false,
  };

  async componentDidMount() {
    try {
      const user = await API.me();
      await this.setUser(user.data);
    } catch (err) {}

    this.setState({ ...this.state, loaded: true });
  }

  render() {
    if (!this.state.loaded) {
      return null;
    }

    return (
      <AppContext.Provider value={this.state}>
        <BrowserRouter>
          <React.Suspense fallback={loading}>
            <Switch>
              <Route
                exact
                path="/login"
                name="Login Page"
                render={(props) => <Login {...props} />}
              />
              <Route
                exact
                path="/404"
                name="Page 404"
                render={(props) => <Page404 {...props} />}
              />
              <Route
                exact
                path="/500"
                name="Page 500"
                render={(props) => <Page500 {...props} />}
              />
              <Route
                path="/"
                name="Home"
                render={(props) => <TheLayout {...props} />}
              />
            </Switch>
          </React.Suspense>
        </BrowserRouter>
      </AppContext.Provider>
    );
  }
}

export default App;
