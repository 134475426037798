import React from "react";

const AppContext = React.createContext({
  user: null,
  setUser: () => {},
  loading: false,
  setLoading: () => {},
});

export default AppContext;
