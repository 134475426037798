import axios from "axios";
axios.defaults.withCredentials = true;
axios.defaults.headers = {
  ...axios.defaults.headers,
  "Content-Type": "application/json",
};

const API = {
  get(endpoint) {
    return axios
      .get(`${process.env.REACT_APP_SERVER}${endpoint}`)
      .catch((err) => API.checkToken(endpoint, err));
  },

  post(endpoint, data) {
    return axios
      .post(`${process.env.REACT_APP_SERVER}${endpoint}`, data)
      .catch((err) => API.checkToken(endpoint, err));
  },

  patch(endpoint, data) {
    return axios
      .patch(`${process.env.REACT_APP_SERVER}${endpoint}`, data)
      .catch((err) => API.checkToken(endpoint, err));
  },

  delete(endpoint) {
    return axios
      .delete(`${process.env.REACT_APP_SERVER}${endpoint}`)
      .catch((err) => API.checkToken(endpoint, err));
  },

  checkToken(endpoint, error) {
    //Redirect to login page if token is invalid (except when checking login status)
    if (
      endpoint !== "/auth/me" &&
      endpoint !== "/auth/login" &&
      error.response.status === 401
    ) {
      window.location.href = "/login";
    } else {
      throw error;
    }
  },

  login(username, password) {
    return this.post("/auth/login", { username, password });
  },

  logout() {
    return this.post("/auth/logout");
  },

  me() {
    return this.get("/auth/me");
  },

  accounts() {
    return this.get("/accounts");
  },

  account(id) {
    return this.get(`/accounts/${id}`);
  },

  createAccount(data) {
    return this.post(`/accounts`, data);
  },

  updateAccount(id, data) {
    return this.patch(`/accounts/${id}`, data);
  },

  deleteAccount(id) {
    return this.delete(`/accounts/${id}`);
  },
};

export default API;
